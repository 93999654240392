<template>
  <div class="load-dialog" style="text-align:center; max-width:500px;margin:0px auto;">
    <el-select v-model="templateValue" placeholder="" style="margin:10px auto;">
      <el-option key="normal" label="标准模板(原出库单模板)" value="normal" />
      <!-- <el-option key="zhaohang" label="招行平台订单模板格式" value="zhaohang" /> -->
      <!-- <el-option key="douyin" label="抖音平台订单模板格式" value="douyin" /> -->
      <el-option key="douyin-wdgj" label="店管家(抖音)订单模板格式" value="douyin-dgj" />
      <!-- <el-option key="tianmao" label="天猫平台订单模板格式" value="tianmao" /> -->
    </el-select>

    <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" drag :on-change="handleChange"
      :on-remove="handleRemove" :on-exceed="handleExceed" :file-list="fileList" :http-request="submitUpload"
      :auto-upload="true" :limit="1">
      <el-icon style="font-size:3em;">
        <UploadFilled />
      </el-icon>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip">
        注：Excel表格里的填写项请严格按照模板内容进行填写
      </div>
    </el-upload>

    <el-link type="primary" @click="download()">点击下载该平台的订单导入模板.xlsx</el-link>

    <div class="errtext" v-if="errortext" style="margin-top:10px;">
      {{ errortext }}
    </div>
  </div>
</template>
<script>
import { uploader } from "@/api/subupload";
import { importOrder } from "@/api/order";
export default {
  name: "OrderUpload",
  data() {
    return {
      templateValue: 'normal',
      loading: false,
      errortext: '',
      fileList: [],
    };
  },
  methods: {
    //添加文件事件
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    //删除文件事件
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    //超出个数事件
    handleExceed(file, fileList) {
      this.$message({
        message: "每次只能上传一个文件",
        type: "warning",
      });
    },

    //上传操作
    submitUpload(file) {
      this.loading = true;
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", "ImportOrder");
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success", });
            this.dataId = res.data[0].id;
          } else {
            this.$message.error("上传失败，请重试");
          }
        })
        .then(() => {
          this.loading = false;
          importOrder(this.templateValue, this.dataId).then(
            (res) => {
              if (res.code != 200) {
                //this.list = Object.values({ ...res.data });
              }
              this.errortext = res.content;
              this.$alert(res.content, '提示')
              this.$emit("freshen");
            },
            (err) => {
              //this.resList = err.data;
              this.errortext = err.content;
              this.$alert(err.content, '提示')
            }
          );
        });

      this.fileList = [];
    },
    download() {
      if (this.templateValue == 'douyin')
        window.location.href = process.env.VUE_APP_BASEURL + "static/order/" + this.templateValue + ".csv?t="+Math.random();
      else
        window.location.href = process.env.VUE_APP_BASEURL + "static/order/" + this.templateValue + ".xlsx?t="+Math.random();
    }
  },
  mounted() {

  },
};
</script>
<style lang="less"></style>