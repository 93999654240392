import request from '@/utils/request'

export function fetchPreInfos(params) {
  return request({
    url: '/OrderPreInfo/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}


export function fetchOrderDetail(id) {
  return request({
    url: '/OrderPreInfo/' + id,
    method: 'get'
  });
}


export function delPreInfo(id) {
  return request({
    url: '/OrderPreInfo/' + id,
    method: 'delete'
  });
}
export function deleteOrder(params) {
  return request({
    url: '/OrderPreInfo/DeleteBatch',
    method: 'delete',
    data: params
  });
}

export function autoFitOrder(params) {
  return request({
    url: '/OrderPreInfo/AutoFitOrder',
    method: 'post',
    data: params
  });
}
export function confirmOrder(params) {
  return request({
    url: '/OrderPreInfo/ConfirmOrder',
    method: 'post',
    data: params
  });
}


export function markBizOrder(params, bizModel) {
  return request({
    url: '/OrderPreInfo/MarkBizOrder?bizModel=' + (bizModel || '10'),
    method: 'post',
    data: params
  });
}


export function closePreInfo(params, reason) {
  return request({
    url: '/OrderPreInfo/CloseOrder?reason=' + reason,
    method: 'post',
    data: params
  });
}

export function pausePreInfo(params, reason) {
  return request({
    url: '/OrderPreInfo/PauseOrder?reason=' + reason,
    method: 'post',
    data: params
  });
}
export function restoreOrder(params) {
  return request({
    url: '/OrderPreInfo/RestoreOrder',
    method: 'post',
    data: params
  });
}


export function submitOrder(id, params) {
  return request({
    url: '/OrderPreInfo/' + (id || ''),
    method: id ? 'put' : 'post',
    data: params
  });
}


export function prepareOrder(fileID) {
  return request({
    url: 'OrderPreInfo/PrepareOrder?FileID=' + fileID,
    method: 'get',
    loadTarget: 'load-dialog'
  });
}

export function orderPostInfo(fileID) {
  return request({
    url: 'OrderPreInfo/OrderPostInfo?FileID=' + fileID,
    method: 'get',
    loadTarget: 'load-dialog'
  });
}

export function reviewOrder(serialCode, postNo) {
  return request({
    url: 'OrderPreInfo/ReviewOrder?serialCode=' + serialCode + "&postNo=" + postNo,
    method: 'get'
  });
}
export function reviewQueryOrder(keyword) {
  return request({
    url: 'OrderPreInfo/ReviewQueryOrder?keyword=' + keyword,
    method: 'get'
  });
}


export function importOrderFee(fileID) {
  return request({
    url: 'OrderPreInfo/ImportOrderFee/?FileID=' + (fileID || ''),
    method: 'get',
    loadTarget: 'load-dialog'
  });
}


export function importOrder(platformCode, fileID) {
  return request({
    url: 'OrderImport/Import/' + platformCode + '?FileID=' + fileID,
    method: 'get',
    loadTarget: 'load-dialog'
  });
}
//系统状态发货
export function deliveryBatchOrder(params) {
  return request({
    url: '/OrderPreInfo/DeliveryBatchOrder',
    method: 'post',
    data: params
  });
}
//上传快递信息到各个平台
export function platformDelivery(params) {
  return request({
    url: '/OrderPreInfo/PlatformDelivery',
    method: 'post',
    data: params
  });
}
//系统状态发货
export function SplitOrder(rowData, splitNum) {
  return request({
    url: '/OrderPreInfo/SplitOrder?splitNum=' + splitNum,
    method: 'post',
    data: rowData
  });
}

export function orderPrintDto(dataType, status, selects, platform, ids) {
  return request({
    url: '/OrderPrintInfo/GetPrintData?status=' + (status || '') + "&dataType=" + (dataType || '') + "&platform=" + (platform || '') + "&idsData=" + (ids || ''),
    method: 'post',
    data: selects
  });
}

export function savePrintData(printList, nocheckedList) {
  return request({
    url: '/OrderPrintInfo/SavePrintData',
    method: 'post',
    data: {
      nocheckedList: nocheckedList,
      printList: printList
    }
  });
}

export function cancelPrintData(id) {
  return request({
    url: '/OrderPrintInfo/CancelPrintData/' + id,
    method: 'get'
  });
}
export function exportOrderPrint(params) {
  return request({
    url: '/OrderPrintInfo/ExportOrderPrint',
    method: 'post',
    data: params
  });
}


export function fetchReturnInfos(params) {
  return request({
    url: '/OrderReturn/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}


export function queryOrder(channel, orderNo, serialCode, companyId) {
  return request({
    url: 'OrderReturn/QueryOrder?channel=' + (channel || '')
      + '&orderNo=' + (orderNo || '')
      + '&companyId=' + (companyId || '')
      + '&serialCode=' + (serialCode || ''),
    method: 'get'
  });
}


export function submitReturnOrder(params) {
  return request({
    url: '/OrderReturn/Batch',
    method: 'post',
    data: params
  });
}

export function saveReturnOrder(id, params) {
  return request({
    url: '/OrderReturn/' + (id || ''),
    method: id ? 'put' : 'post',
    data: params
  });
}

export function closeReturnOrder(params, reason) {
  return request({
    url: '/OrderReturn/CloseOrder?reason=' + reason,
    method: 'post',
    data: params
  });
}

export function deleteReturnOrder(id) {
  return request({
    url: '/OrderReturn/' + id,
    method: 'delete'
  });
}

export function confirmReturnOrder(id) {
  return request({
    url: '/OrderReturn/Confirm/' + id,
    method: 'post'
  });
}

export function stockinReturnData(params) {
  return request({
    url: '/OrderReturn/GetStockInData/',
    method: 'post',
    data: params
  });
}

export function stockOriginData(params) {
  return request({
    url: '/OrderReturn/GetOriginData/',
    method: 'post',
    data: params
  });
}

export function fetchOrderSellInfo(params) {
  return request({
    url: '/OrderSellInfo/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}

export function importOrderSellInfo(departmentId, platform, fileID) {
  return request({
    url: 'OrderSellInfo/Import/' + platform + '/?FileID=' + (fileID || '') + '&departmentId=' + (departmentId || ''),
    method: 'get',
    loadTarget: 'load-dialog'
  });
}
export function importOrderModify(fileID) {
  return request({
    url: 'OrderSellInfo/Modify/?FileID=' + (fileID || ''),
    method: 'get',
    loadTarget: 'load-dialog'
  });
}

export function fetchOrderReturn(params) {
  return request({
    url: '/OrderReturn/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}

export function importOrderReturn(departmentId, platform, fileID) {
  return request({
    url: 'OrderReturn/Import/' + platform + '/?FileID=' + (fileID || '') + '&departmentId=' + (departmentId || ''),
    method: 'get',
    loadTarget: 'load-dialog'
  });
}


export function fetchOrderPrint(params) {
  return request({
    url: '/OrderPrintInfo/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}
